import React from "react";

import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import { withAuthenticator } from "aws-amplify-react";
import Notes from "./Notes";

Amplify.configure(aws_exports);

function App() {
  return <Notes />;
}

export default withAuthenticator(App, { includeGreetings: true });

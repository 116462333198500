import React from "react";
import ReactQuill from "react-quill";

class NoteDetail extends React.Component {
  render() {
    const modules = {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ font: [] }],
        ["bold", "italic", "underline", "strike"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" }
        ],
        [{ color: [] }, { background: [] }],
        ["clean"]
      ]
    };
    return (
      <div className="cobalt-editor">
        <input
          data-id={this.props.id}
          className="input"
          type="text"
          placeholder="Title"
          value={this.props.title}
          onChange={this.props.onTitleChange}
        />
        <ReactQuill
          data-id={this.props.id}
          modules={modules}
          value={this.props.content}
          onChange={this.props.onContentChange}
          preserveWhitespace={true}
        />
      </div>
    );
  }
}

export default NoteDetail;

import React from "react";
import update from "immutability-helper";
import NoteItem from "./NoteItem";
import NoteDetail from "./NoteDetail";
import NoteMetadata from "./NoteMetadata";
import { Storage } from "./Storage";

const TIMEOUT = 5000;

class Notes extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnSelected = this.handleOnSelected.bind(this);
    this.handleOnAdd = this.handleOnAdd.bind(this);
    this.handleOnTitleChange = this.handleOnTitleChange.bind(this);
    this.handleOnContentChange = this.handleOnContentChange.bind(this);
    this.handleOnDelete = this.handleOnDelete.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);

    this.state = {
      notes: [],
      dirty: false,
      state: "Saved"
    };
  }

  componentDidMount() {
    Storage.query()
      .then(items => {
        this.setState({
          notes: items,
          selected: items[0] ? items[0].id : null
        });
      })
      .catch(() => {
        this.setState({
          notes: {}
        });
      });
  }

  handleOnSelected(event) {
    const id = event.target.dataset["id"];
    const index = this.getNoteIndex(id);
    this.setState({
      selected: id,
      notes: update(this.state.notes, {
        [index]: { deleted: { $set: false } }
      })
    });
  }

  handleOnDelete(event) {
    const id = event.target.dataset["id"];
    const index = this.getNoteIndex(id);
    const note = this.state.notes[index];
    if (note.deleted) {
      Storage.delete({ id: id }).then(() => {
        this.setState({
          notes: update(this.state.notes, {
            $splice: [[index, 1]]
          })
        });
      });
    } else {
      this.setState({
        notes: update(this.state.notes, {
          [index]: { deleted: { $set: true } }
        })
      });
    }
  }

  handleOnTitleChange(event) {
    if (this.state.contentChangeTimeoutId) {
      clearTimeout(this.state.contentChangeTimeoutId);
      delete this.state.contentChangeTimeoutId;
    }
    const id = event.target.dataset["id"];
    const index = this.getNoteIndex(id);
    if (index >= 0) {
      this.setState({
        dirty: true,
        state: "Save",
        notes: update(this.state.notes, {
          [index]: { title: { $set: event.target.value } }
        }),
        contentChangeTimeoutId: setTimeout(
          () => this.saveContent(index),
          TIMEOUT
        )
      });
    }
  }

  handleOnContentChange(text, delta, source) {
    if (source === "api") {
      // no need to save
      return;
    }
    if (this.state.contentChangeTimeoutId) {
      clearTimeout(this.state.contentChangeTimeoutId);
      delete this.state.contentChangeTimeoutId;
    }
    const index = this.getNoteIndex(this.state.selected);
    if (index >= 0) {
      this.setState({
        dirty: true,
        state: "Save",
        notes: update(this.state.notes, {
          [index]: { content: { $set: text } }
        }),
        contentChangeTimeoutId: setTimeout(
          () => this.saveContent(index),
          TIMEOUT
        )
      });
    }
  }

  handleOnSave() {
    if (this.state.contentChangeTimeoutId) {
      clearTimeout(this.state.contentChangeTimeoutId);
      delete this.state.contentChangeTimeoutId;
    }
    const index = this.getNoteIndex(this.state.selected);
    this.saveContent(index);
  }

  saveContent(index) {
    if (index >= 0) {
      const note = this.state.notes[index];
      this.setState({
        dirty: false,
        state: "Saving..."
      });
      Storage.write({
        id: note.id,
        title: note.title,
        content: note.content,
        createTimestamp: note.createTimestamp
      })
        .then(updateTimestamp => {
          this.setState({
            dirty: false,
            state: "Saved",
            notes: update(this.state.notes, {
              [index]: { updateTimestamp: { $set: updateTimestamp } }
            })
          });
        })
        .catch(() =>
          this.setState({
            dirty: true,
            state: "Save"
          })
        );
    }
  }

  getNoteIndex(id) {
    return this.state.notes.findIndex(note => note.id === id);
  }

  handleOnAdd() {
    Storage.create().then(({ uuid, createTimestamp }) => {
      this.setState({
        notes: update(this.state.notes, {
          $push: [
            {
              id: uuid,
              createTimestamp: createTimestamp,
              title: "",
              content: ""
            }
          ]
        }),
        selected: uuid
      });
    });
  }

  render() {
    const index = this.getNoteIndex(this.state.selected);
    const selectedNote = index >= 0 ? this.state.notes[index] : null;

    return (
      <div className="columns">
        <div className="column is-1" />
        <div className="column is-2">
          <table className="table is-hoverable is-fullwidth">
            <thead className="thead">
              <tr className="tr">
                <td className="td">Notes</td>
              </tr>
            </thead>
            <tbody className="tbody">
              {this.state.notes.map(note => (
                <NoteItem
                  key={note.id}
                  id={note.id}
                  title={note.title}
                  selected={this.state.selected === note.id}
                  onSelected={this.handleOnSelected}
                  onDelete={this.handleOnDelete}
                  deleted={note.deleted}
                />
              ))}
            </tbody>
          </table>
          <button className="button is-info" onClick={this.handleOnAdd}>
            <span className="fas fa-plus-circle" />
            &nbsp;Add Note
          </button>
        </div>
        <div className="column is-6">
          {selectedNote ? (
            <div>
              <NoteDetail
                id={selectedNote.id}
                title={selectedNote.title}
                content={selectedNote.content}
                onTitleChange={this.handleOnTitleChange}
                onContentChange={this.handleOnContentChange}
              />
              <NoteMetadata
                createTimestamp={selectedNote.createTimestamp}
                updateTimestamp={selectedNote.updateTimestamp}
                onSave={this.handleOnSave}
                state={this.state.state}
                disabled={!this.state.dirty}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
        <div className="column is-2" />
        <div className="column is-1" />
      </div>
    );
  }
}

export default Notes;

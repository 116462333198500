import React from "react";

class NoteItem extends React.Component {
  render() {
    const className = this.props.deleted
      ? "has-background-warning tr"
      : this.props.selected
      ? "is-selected tr"
      : "tr";
    return (
      <tr className={className}>
        <td className="td">
          <button
            onClick={this.props.onSelected}
            data-id={this.props.id}
            className={"cobalt-button"}
          >
            {this.props.title === "" ? "<No Title>" : this.props.title}
          </button>
        </td>
        <td className="td">
          <button
            onClick={this.props.onDelete}
            data-id={this.props.id}
            className="delete"
          />
        </td>
      </tr>
    );
  }
}

export default NoteItem;

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "eu-west-1",
    "aws_content_delivery_bucket": "cralian-cobalt-20181208010701--hostingbucket",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3mkznx7v5onx7.cloudfront.net",
    "aws_cognito_identity_pool_id": "eu-west-1:776a94ec-deb1-4996-84c5-b82b9fdddba8",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_GIKaCGaUl",
    "aws_user_pools_web_client_id": "7bn1b7gqn66rsias1rflge2m07",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Notes.1",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;

import React from "react";

class NoteMetadata extends React.Component {
  render() {
    const createTimestamp = new Date(this.props.createTimestamp).toLocaleString(
      "en-GB",
      {
        timeZone: "Europe/London"
      }
    );
    const updateTimestamp = this.props.updateTimestamp
      ? new Date(this.props.updateTimestamp).toLocaleString("en-GB", {
          timeZone: "Europe/London"
        })
      : null;
    return (
      <div>
        <button
          className={"button is-info"}
          onClick={this.props.onSave}
          disabled={this.props.disabled}
        >
          <span className={"icon"}>
            <i className={"fas fa-save"} />
          </span>
          <span>{this.props.state}</span>
        </button>
        <ul className={"is-size-7"}>
          <li>Created: {createTimestamp}</li>
          {updateTimestamp ? <li>Last Updated: {updateTimestamp}</li> : null}
        </ul>
      </div>
    );
  }
}

export default NoteMetadata;

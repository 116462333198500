import DynamoDB from "aws-sdk/clients/dynamodb";
import { Auth } from "aws-amplify";
const uuidv1 = require("uuid/v1");

function itemise(data) {
  let items = data.Items.map(item =>
    Object.assign(
      {
        id: item.Id.S,
        user: item.User.S
      },
      item.Title && item.Title.S ? { title: item.Title.S } : { title: "" },
      item.Content && item.Content.S
        ? { content: item.Content.S }
        : { content: "" },
      item.CreateTimestamp && item.CreateTimestamp.S
        ? { createTimestamp: item.CreateTimestamp.S }
        : {},
      item.UpdateTimestamp && item.UpdateTimestamp.S
        ? { updateTimestamp: item.UpdateTimestamp.S }
        : {}
    )
  );
  sortByCreateTime(items);
  return items;
}

function sortByCreateTime(items) {
  items.sort((e1, e2) => {
    if (e1.createTimestamp < e2.createTimestamp) {
      return -1;
    }
    if (e1.createTimestamp > e2.createTimestamp) {
      return 1;
    }
    return 0;
  });
}

function getDynamoDB(credentials) {
  return new DynamoDB({
    apiVersion: "2012-08-10",
    credentials: Auth.essentialCredentials(credentials),
    region: "eu-west-1"
  });
}

function newNote(uuid, createTimestamp) {
  return {
    id: uuid,
    title: "",
    content: "",
    createTimestamp: createTimestamp
  };
}

function writeParams(id, title, content, createTimestamp, user) {
  return {
    Item: Object.assign(
      {
        Id: {
          S: id
        },
        User: {
          S: user.attributes.sub
        },
        UpdateTimestamp: {
          S: new Date().toISOString()
        }
      },
      createTimestamp ? { CreateTimestamp: { S: createTimestamp } } : {},
      title ? { Title: { S: title } } : {},
      content ? { Content: { S: content } } : {}
    ),
    TableName: "Notes.1"
  };
}

function deleteParams(id) {
  return {
    Key: {
      Id: {
        S: id
      }
    },
    TableName: "Notes.1"
  };
}

function queryParams(user) {
  return {
    KeyConditionExpression: "#User = :user",
    ExpressionAttributeValues: {
      ":user": {
        S: user.attributes.sub
      }
    },
    ExpressionAttributeNames: {
      "#User": "User"
    },
    TableName: "Notes.1",
    IndexName: "User"
  };
}

class Storage {
  static create() {
    return new Promise((resolve, reject) => {
      const uuid = uuidv1();
      const createTimestamp = new Date().toISOString();
      return Storage.write(newNote(uuid))
        .then(() => resolve({ uuid: uuid, createTimestamp: createTimestamp }))
        .catch(err => reject(err));
    });
  }

  static write({ id, title, content, createTimestamp }) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials()
        .then(credentials => {
          const dynamodb = getDynamoDB(credentials);

          Auth.currentAuthenticatedUser()
            .then(user => {
              const params = writeParams(
                id,
                title,
                content,
                createTimestamp,
                user
              );
              dynamodb.putItem(params, (err, data) => {
                if (err) reject(err);
                else resolve(params.Item.UpdateTimestamp.S);
              });
            })
            .catch(err => reject(err));
        })
        .catch(err => reject(err));
    });
  }

  static query() {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials()
        .then(credentials => {
          const dynamodb = getDynamoDB(credentials);

          Auth.currentAuthenticatedUser()
            .then(user => {
              const params = queryParams(user);

              dynamodb.query(params, (err, data) => {
                if (err) reject(err);
                else resolve(itemise(data));
              });
            })
            .catch(err => reject(err));
        })
        .catch(err => reject(err));
    });
  }

  static delete({ id }) {
    return new Promise((resolve, reject) => {
      Auth.currentCredentials()
        .then(credentials => {
          const dynamodb = getDynamoDB(credentials);
          const params = deleteParams(id);

          dynamodb.deleteItem(params, err => {
            if (err) reject(err);
            else resolve();
          });
        })
        .catch(err => reject(err));
    });
  }
}

export { Storage };
